.animation-pulse {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 7px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
    }
}