@import "utilities/animations.css";
@import "utilities/intro.css";
@import '../../node_modules/swiper/swiper-bundle.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --swiper-wrapper-transition-timing-function: linear !important;
}

.container {
    max-width: 100%;
}

.lb-shadow {
    box-shadow: 0 0px 9px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)
}

.theme-container {
    max-width: 1330px;
    padding: 0 20px;
}

.win-grid {
    letter-spacing: 2px;
    display: flex;
    flex-wrap: wrap;
}

.win-btn {
    width: 28px;
    height: 28px;
    text-align: center;
    border: none;
    border-radius: 0px;
    border: 1px solid #e5e7ec6b;
}

#hero-bg {
    background-color: #e5e5f7;
    opacity: 0.2;
    background-image:  radial-gradient(#000000 0.55px, transparent 0.55px), radial-gradient(#000000 0.55px, #f3f4f6 0.55px);
    background-size: 22px 22px;
    background-position: 0 0,11px 11px;
}

#hero2-bg {
    background-color: #000;
    background-image: radial-gradient(rgba(255,255,255,0.3) 0.55px, transparent 0.55px), radial-gradient(rgba(255,255,255,0.3) 0.55px, #000000 0.55px);
    background-size: 22px 22px;
    background-position: 0 0,11px 11px;
}

#hero3-bg {
    background-color: #3f3d56;
    background-image: radial-gradient(rgba(255,255,255,0.25) 0.55px, transparent 0.55px), radial-gradient(rgba(255,255,255,0.25) 0.55px, #3f3d56 0.55px);
    background-size: 22px 22px;
    background-position: 0 0,11px 11px;
}

.top-bottom-moving {
    animation: top-bottom-anim 3s linear 0s infinite alternate;
}

@keyframes top-bottom-anim {
    0% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(10px);
    }
}

.animated_circle_bg {
    position: absolute;
    width: 0px;
    height: 0px;
    border-radius: 100%;
    animation: anim_circle_bg 4s linear 0s infinite;
}
.animated_circle_bg2 {
    position: absolute;
    width: 0px;
    height: 0px;
    border-radius: 100%;
    animation: anim_circle_bg 4s linear 0.7s infinite;
}
.animated_circle_bg3 {
    position: absolute;
    width: 0;
    height: 0;
    border-radius: 100%;
    animation: anim_circle_bg 4s linear 1.4s infinite;
}
.animated_circle_bg4 {
    position: absolute;
    width: 0;
    height: 0;
    border-radius: 100%;
    animation: anim_circle_bg 4s linear 2.1s infinite;
}

.animated_circle_bg5 {
    position: absolute;
    width: 0;
    height: 0;
    border-radius: 100%;
    animation: anim_circle_bg 4s linear 2.8s infinite;
}
.animated_circle_bg6 {
    position: absolute;
    width: 0;
    height: 0;
    border-radius: 100%;
    animation: anim_circle_bg 4s linear 3.5s infinite;
}

@keyframes anim_circle_bg {
    0% {
        opacity: 0.3;
        width: 0;
        height: 0;
    }
    80% {
        opacity: 0.15;
    }
    100% {
        width: 1035px;
        height: 1035px;
        opacity: 0;
    }
}

.iub_content.legal_pp {
    padding: 0!important;
}

.iub_content.legal_pp h1  {
    display: none!important;
}

.iub_container.iub_base_container {
    margin-top: 0!important;
}

footer {
    box-shadow: 0 -4px 6px -1px rgb(0 0 0 / 0.1), 0 -2px 4px -2px rgb(0 0 0 / 0.1);
}

.pricing-swiper {
    padding: 1rem !important;
    margin: 0 -1rem;
}

.pricing-swiper .swiper-slide {
    height: auto;
    padding: 0.5rem;
}

/* Make cards same height */
.pricing-card {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.pricing-features {
    flex-grow: 1;
}

/* Custom pagination */
.pricing-pagination {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    gap: 0.5rem;
    height: 8px;
}

.pricing-pagination .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background: #D1D5DB;
    opacity: 1;
    transition: all 0.3s ease;
    border-radius: 9999px;
    display: inline-block;
    margin: 0 4px;
}

.pricing-pagination .swiper-pagination-bullet-active {
    background: #000;
    width: 24px;
    border-radius: 9999px;
}

/* Fix bullet container positioning */
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0;
    width: auto;
    position: static;
}

@media (min-width: 1024px) {
    .pricing-swiper {
        display: none;
    }
}
